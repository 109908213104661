import styled from "@emotion/styled";
import UploadFileBlock from "../../../shared-components/UploadFileBlock";
import { DashInput } from "../../../shared-components/DashInput";
import { uploadFile } from "../../../api/uploadFile";

export default function FragmentImage({ handleChange, stateForm }) {
  //TODO: pensar melhor nisso
  //TODO: fix in env

  const handleGetFile = (event) => {
    const file = event.target.files[0];
    
    uploadFile(file).then((res) => {
      handleChange({
        target: {
          name: "largeImage",
          value: `https://bucket-westand-prod.s3.us-east-2.amazonaws.com/${res.name}`,
        },
      });
    });
  };

  return (
    <>
      <span>Informações básicas</span>
      <p>Adicione as principais informações do evento.</p>
      <Row>
        <DashInput
          title="Nome do evento"
          placeholder="Nome do evento"
          fullWidth
          width="60%"
          required
          name="name"
          value={stateForm?.name}
          onChange={handleChange}
        />
      </Row>
      <p>Imagem de divulgação (opcional).</p>
      <Row>
        <UploadFileBlock
          name="image"
          width="300px"
          height="200px"
          getFile={handleGetFile}
          imageFile={stateForm?.largeImage ? stateForm.largeImage : null}
        />
        <p>
          A dimensão recomendada é de 1600 x 838 (mesma proporção do formato
          utilizado nas páginas de evento no Facebook). Formato JPEG, GIF ou PNG
          de no máximo 2MB. Imagens com dimensões diferentes serão
          redimensionadas.
        </p>
      </Row>
    </>
  );
}

const Row = styled.div`
  display: flex;
  align-items: start;
  width: 100%;
  gap: 30px;
  margin: 2rem 0;
  > p {
    flex: 2;
  }
`;
