import React, {
  useState,
  forwardRef,
} from 'react';
import styled from '@emotion/styled';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { generateImageLink } from '../helpers/generateImageLink';
import LinearLoading from './LinearLoading';
import { uploadFile } from '../api/uploadFile';

const bucketParams = {
  Bucket: "bucket-westand-prod",
};

function shortFileName(name) {
  if (!name) return ""
  return `${name.substring(0, 20)}...`
}

export const UploadFileDownload = forwardRef((props, ref) => {

  const {
    getFile,
    defaultValue,
    title,
    width,
    link,
    tooltip,
    disabled = false,
    ...otherProps } = props;

  const [file, setFile] = useState(() => shortFileName(link));
  const [status, setStatus] = useState(null)



  if (defaultValue) {
    setStatus("Arquivo enviado!")
  }

  function handleInput(e) {
    const type = e.target.files[0].type.split("/")[1]
    const file = e.target.files[0];
    
    setFile(`${e.target.files[0].name}.${type}`);
    setStatus('Aguarde enviando arquivo...')
    uploadFile(file)
      .then(res => {
        setStatus(() => shortFileName(e.target.files[0].name));
        getFile(e, res);
      })
      .catch(error => {
        console.error(error)
        setStatus('Tente novamente...')
      })
  }

  function downloadFile(e) {
    const file = generateImageLink(link)
    if (!file) return;
    window.open(file?.image)
  }

  if (status === 'Aguarde enviando arquivo...') {
    return (

      <Component width={width}>
        <span>{title}</span>
        <Wrapper
          width={width}
        >
          <UploadContainer
            disabled={true}
          >
            <div style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              margin: '0.6rem 0',

            }}>
              <LinearLoading />
            </div>
          </UploadContainer>

        </Wrapper>
      </Component>

    )
  }


  return (
    <Component width={width}>
      <span>{title}</span>
      <Wrapper
        width={width}
      >
        <UploadContainer disabled={disabled}>
          <label
            title={tooltip}
          >
            <CloudUploadOutlinedIcon />
            <input
              type="file"
              onChange={handleInput}
              {...otherProps}
              disabled={disabled}
              ref={ref}
            />
          </label>
          <div>
            {status || file}
          </div>
        </UploadContainer>

        <CloseIcon
          title="Fazer downalod do arquivo"
          onClick={downloadFile}
          disabled={!file ? true : false}

          {...otherProps}>
          <CloudDownloadIcon />
        </CloseIcon>
      </Wrapper>
    </Component>
  )
})

const Component = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  
`

const UploadContainer = styled.div`

  position: relative;
  display: flex;
  background-color:#E3E3E3;
  border-radius: 20px;
  justify-content: space-between;
  height: 30px;
  width: 100%;
  min-width: 32rem;

  cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? '0.6' : '1'};

   > label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9rem;
  height: 30px;
  background-color: #585858;
  border-radius: 20px;
  cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? '0.6' : '1'};
 }

 
 >div:first-of-type {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  font-size: 1.8rem;
  padding: 0 10px;
  width: 80%;
  height: 30px;
 }
`

const CloseIcon = styled.div`
  position: absolute;
  right: 1px;
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#EE9C15 ;
  border-radius: 20px;
  width: 9rem;
  font-size: 2.4rem;
  color: white;
  font-weight: 500;
  height: 30px;
  cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};
    opacity: ${props => props.disabled ? '0.6' : '1'};

  &:hover {
    filter: brightness(110%);
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  background-color:#E3E3E3;
  border-radius: 20px;
  justify-content: space-between;
  height: 30px;
  width: 100%;
  min-width: 32rem;
  opacity: ${props => props.disabled ? '0.6' : '1'};
  


 svg {
  color: white;
 }

`

