const API = process.env.REACT_APP_API;

export async function uploadFile(file) {

  const formData = new FormData();
  formData.append('file', file);

  const res = await fetch(`${API}/upload-file`, {
    method: 'POST',
    headers: {
      ContentType: 'multipart/form-data',
    },
    body: formData,
  });
  if (res.ok) {
    return res.json();
  }
  const error = await res.json()
  throw error;
}