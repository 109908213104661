import React, { useState } from "react";
import styled from "@emotion/styled";
import { MenuItem, RadioGroup, Radio } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Autocomplete } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import UploadFile from "../../shared-components/UploadFile";
import { useForm, Controller, useFormContext } from "react-hook-form";
import { NTextField } from "../../shared-components";
import { searchEvents, searchCities } from "../../api/open";
import { useBriefing } from "./hooks/useBriefing";
import { NSelect } from "../../shared-components";
import { listCauses } from "../../api/promoter";
import { uploadFile } from "../../api/uploadFile";

const useStyles = makeStyles(() => ({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
}));

const EventoInformation = ({ theme, briefing, setBriefing }) => {
  const classes = useStyles();
  const { control, setValue, getValues } = useFormContext();
  const [alignment, setAlignment] = useState("open");
  const [checked, setChecked] = useState(false);
  const [text, setText] = useState("");
  const [events, setEvents] = useState([]);
  const [cities, setCities] = useState([]);
  const [searchParams] = useSearchParams();

  const {
    register,
    formState: { errors },
  } = useForm();

  if (!getValues("briefingType")) {
    setValue("briefingType", "PROJETO");
  }


  if (briefing.cidade || searchParams.get('city')) {
    setValue("cidade", briefing.cidade || searchParams.get('city'));
  }

  if (briefing.evento) {
    setValue("evento", briefing.evento)
  }

  const handleChange = (event, newAlignment) => {
    if (!newAlignment) return;
    setAlignment(newAlignment);
  };

  async function handleSearchEvent(e) {
    if (!e) return;
    const result = await searchEvents(e.target.value);
    if (result.length >= 1) {
      setEvents(
        result.map((v) => ({
          id: v.id,
          label: v.name,
        }))
      );
      return;
    }
    setEvents([]);
  }

  async function handleSearchCities(e) {
    if (!e) return;
    const result = await searchCities(e.target.value);
    if (result.length >= 1) {
      setCities(
        result.map((v) => ({
          id: v.id,
          label: v.name,
        }))
      );
      return;
    }
    setCities([]);
  }

  function handleOtherTypeInput(e, field) {
    field.onChange(e.target.value);
    if (e.target.value === "OUTRO") {
      setChecked(true);
      return;
    }
    setChecked(false);
  }

  const handleRemoveFile = () => {
    setValue("projetctUpload", "");
  };

  const handleGetFile = (event, result) => {
    const type = event.target.files[0].type.split("/")[1]
    const file = event.target.files[0]

    uploadFile(file)
      .then(res => {
        setValue('projetctUpload', {
          type: 'upload',
          link: `${result.key}.${type}`,
          id: file.target.id,
        })
      })
  }


  return (
    <>
      <h3>Vamos falar sobre seu evento...</h3>
      <Container>
        <EventInfo>
          <Controller
            control={control}
            name="evento"
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <Autocomplete
                freeSolo
                onInputChange={handleSearchEvent}
                defaultValue={{ label: briefing.evento }}
                options={events}
                onChange={(event, value) => {
                  if (value) {
                    setValue("evento", value.label)
                    setValue("eventoId", value.id)
                  }
                }}
                renderInput={(params) => (
                  <NTextField
                    id="input-event"
                    name="evento"
                    inputRef={ref}
                    variant="outlined"
                    fullWidth
                    title={"Qual o nome do evento?"}
                    margin="normal"
                    {...register("evento")}
                    placeholder="Deixe em branco caso não seja para um evento"
                    {...field}
                    {...params}
                  />
                )}
              />
            )}
          />
          <p>Se tiver projeto, anexar</p>
          <UploadFile
            id=""
            getFile={handleGetFile}
            deleteFile={handleRemoveFile}
          />
          <EventTypeContainer>
            <Controller
              control={control}
              name="briefingType"
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  onChange={(e) => handleOtherTypeInput(e, field)}
                  value={field.value || "PROJETO"}
                  className={classes.Radio}
                >
                  <FormControlLabel
                    value="PROJETO"
                    control={<Radio />}
                    label="Preciso de um projeto"
                  />
                  <FormControlLabel
                    value="PROJETO_MONTADORA"
                    control={<Radio />}
                    label="Preciso de um projeto + montadora"
                  />
                  <FormControlLabel
                    value="MONTAGEM"
                    control={<Radio />}
                    label="Preciso de montagem"
                  />
                  <FormControlLabel
                    value="OUTRO"
                    control={<Radio />}
                    label="Outro"
                  />
                  <Controller
                    control={control}
                    name="outro"
                    render={({ field }) => (
                      <NTextField
                        id="input-place"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="text"
                        disabled={!checked}
                        onChange={(e) => setText(e.target.value)}
                        {...field}
                        placeholder="O que você precisa?"
                      />
                    )}
                  />
                </RadioGroup>
              )}
            />
          </EventTypeContainer>
        </EventInfo>
        <EventLocation>

          <Controller
            control={control}
            name="cidade"

            render={({ field: { ref, ...field } }) => (
              <Autocomplete
                freeSolo
                onInputChange={handleSearchCities}
                options={cities}
                defaultValue={{ label: briefing.cidade }}
                onChange={(event, value) => {
                  if (value) {
                    setValue("cidade", value.label)
                  }
                }}
                renderInput={(params) => (
                  <NTextField
                    id="input-city"
                    name="cidade"
                    inputRef={ref}
                    {...register("cidade")}
                    title={"Qual cidade?"}
                    margin="normal"
                    placeholder="Pesquise a cidade"
                    {...field}
                    {...params}
                  />
                )} />
            )}
          />
          <Controller
            control={control}
            name="local"
            render={({ field }) => (
              <NTextField
                name="local"
                id="input-place"
                variant="outlined"
                {...register("local")}
                fullWidth
                title={"Qual local?"}
                margin="normal"
                {...field}
                placeholder="Pesquise o local"

              />
            )}
          />

          <Controller
            control={control}
            name="causa"
            render={({ field }) => (
              <NSelect
                name="causa"
                id="input-causa"
                variant="outlined"
                {...register("causa")}
                fullWidth
                title={"Qual causa você quer apoiar?"}
                defaultValue=""
                {...field}
              >
                {listCauses().map((v) => (
                  <MenuItem value={v.name} key={v.id}>{v.name}</MenuItem>
                ))}
              </NSelect>
            )}
          />

          <OptionsContainer>
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="open">Abrir concorrência</ToggleButton>
              <ToggleButton value="mont">Indicar montadora</ToggleButton>
            </ToggleButtonGroup>
            {alignment === "open" ? (
              <p>Haverá concorrência de montadoras</p>
            ) : (
              <>
                <p>Escreva o nome da(s) montadora(s) abaixo</p>
                <NTextField placeholder="Montadora(s)" fullWidth />
              </>
            )}
          </OptionsContainer>
        </EventLocation>
      </Container>
    </>
  );
};

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  gap: 20px;

  & .MuiButtonBase-root.MuiToggleButton-root.MuiToggleButtonGroup-grouped {
    padding: 10px !important;
    border: 1px solid #ff8400 !important;
    font-size: 1.2rem !important;
    color: #ff8400;
  }

  & .MuiToggleButtonGroup-grouped.Mui-selected {
    background-color: #ff8400;
    color: white !important;
  }

  & .MuiToggleButtonGroup-grouped.Mui-selected:hover {
    background-color: #ff840099 !important;
  }
`;


const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
`;

const EventInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: #181818;
  flex: 1;

  > p {
    margin: 10px 0;
  }


  & .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root svg {
    display: none;
  }
`;

const EventTypeContainer = styled.div`
  display: flex;

  &
    .MuiFormGroup-root
    .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
    display: flex;
    flex-direction: row;
    margin: 0 !important;
  }

  & .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
    font-size: 1.2rem;
    font-weight: 400;
  }

  & .MuiRadio-colorSecondary.Mui-checked {
    color: #ff8400 !important;
  }
`;

const EventLocation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 50%;

    & .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root svg {
    display: none;
  }
`;

export default EventoInformation;