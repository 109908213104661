import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import styled from '@emotion/styled';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DisabledByDefaultSharpIcon from '@mui/icons-material/DisabledByDefaultSharp';
import { uploadFile } from '../api/uploadFile';

const bucketParams = {
  Bucket: "bucket-westand-prod",
};

const UploadFile = forwardRef((props, ref) => {

  const {
    getFile,
    deleteFile,
    disabled,
    defaultValue,
    title,
    width,
    link,
    ...otherProps } = props;

  const [file, setFile] = useState(link);
  const [status, setStatus] = useState(null)

  if (defaultValue) {
    setStatus("Arquivo enviado!")
  }


  function handleInput(e) {
    const type = e.target.files[0].type.split("/")[1]
    const file = e.target.files[0];
    
    setFile(`${e.target.files[0].name}.${type}`);
    setStatus('Enviando arquivo...')
    uploadFile(file)
      .then(res => {
        setStatus(`Sucesso: ${e.target.files[0].name.substring(0, 20)}...`);
        getFile(e, res);
      })
      .catch(error => {
        console.error(error)
        setStatus('Tente novamente...')
      })
  }

  function removeFile(e) {
    setStatus(null)
    setFile(null);
    deleteFile(e);
  }

  return (
    <Component width={width}>
      <span>{title}</span>
      <Wrapper
        disabled={disabled}
      >
        <label>
          <CloudUploadOutlinedIcon />
          <input
            type="file"
            onChange={handleInput}
            {...otherProps}
            disabled={disabled}
            ref={ref}
          />
        </label>
        <div>
          {status || file}
        </div>
        <CloseIcon onClick={removeFile} {...otherProps}>x</CloseIcon>
      </Wrapper>
    </Component>
  )
})

const Component = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`


const CloseIcon = styled.div`
  position: absolute;
  right: 1px;
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#EE9C15 ;
  border-radius: 12px 20px 20px 12px;
  width: 40px;
  font-size: 2.4rem;
  color: white;
  font-weight: 500;
  height: 30px;
  cursor: pointer;

  &:hover {
    filter: brightness(110%);
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  background-color:#E3E3E3;
  border-radius: 20px;
  justify-content: space-between;
  height: 30px;
  width: 100%;
  opacity: ${props => props.disabled ? '0.6' : '1'};
  

 > label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 30px;
  background-color: #585858;
  border-radius: 20px;
  cursor: pointer;
 }
 
 >div:first-of-type {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  font-size: 1.8rem;
  padding: 0 10px;
  width: 80%;
  height: 30px;
 }
 svg {
  color: white;
 }


`

export default UploadFile;